<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent width="500" @keydown.esc="onClose">
      <v-card elevation="0">
        <v-card-title class="text-h5 grey lighten-2">
          {{ $t('table.configure-table-headers') }}
          <v-icon right dark large>
            mdi-table-column-remove
          </v-icon>
        </v-card-title>

        <v-card-text>
          <v-row>
            {{ $t('table.drag-and-drop-to-change') }}
          </v-row>
          <v-row>
            <v-col>
              <strong>{{ $t('table.all-fields') }}</strong>
              <div
                id="left"
                class="drop-zone"
                @dragenter="onDragEnter"
                @dragleave="onDragLeave"
                @dragover="onDragOver($event)"
                @drop="onDrop($event)"
              >
                <div
                  v-for="(item, i) in left"
                  :id="1000 + i"
                  :key="item.value"
                  class="drag-el"
                  draggable
                  @dragstart="onDragStart($event, 1000 + i)"
                  @dragover="onDragOver($event)"
                >
                  {{ $t(item.text) }}
                </div>
              </div>
            </v-col>
            <v-col>
              <strong>{{ $t('table.table-selected-fields') }}</strong>
              <div
                id="right"
                :class="rightClass"
                class="drop-zone"
                @dragenter="onDragEnter"
                @dragleave="onDragLeave"
                @drop="onDrop($event)"
                @dragover="onDragOver($event)"
              >
                <div
                  v-for="(item, i) in right"
                  :id="2000 + i"
                  :key="item.value"
                  class="drag-el drop-zone"
                  draggable
                  @dragstart="onDragStart($event, 2000 + i)"
                  @dragover="onDragOver($event)"
                >
                  {{ $t(item.text) }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn text color="secondary" @click="onClose()">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onSave()">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    all: {
      type: Array,
      required: true
    },
    selected: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      dialog: this.value,
      right: [...this.selected],
      inLeft: 0
    };
  },

  computed: {
    left() {
      return this.all.filter(e => !this.right.some(re => re.value === e.value));
    },
    rightClass() {
      return this.right.length > 0 ? '' : 'drop-zone';
    }
  },

  watch: {
    value(value) {
      this.dialog = value;
    },
    selected: {
      handler(val) {
        this.right = [...val];
      },
      deep: true
    }
  },

  methods: {
    onSave() {
      this.$emit('selection', this.right);
      this.$emit('input', false);
    },

    onClose() {
      this.$emit('input', false);
    },

    onDragStart(evt, id) {
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('id', id);
      // console.log('onDragStart; id:', id);
    },
    onDragOver(evt) {
      evt.preventDefault();
    },

    onDrop(evt) {
      // dstId:
      // 1 - first list
      // 2 - second list
      // 100x - x-th element on the first list
      // 200x - x-th element on the second list
      const srcId = evt.dataTransfer.getData('id');
      const targetId = evt.target.id;
      // console.log(`onDrop; ${srcId} -> ${targetId}`);

      const fromTheLeft = srcId === 'left' || (srcId >= 1000 && srcId < 2000);

      const toRight =
        targetId === 'right' || (targetId >= 2000 && targetId < 3000);

      const toTheLeft =
        targetId === 'left' || (targetId >= 1000 && targetId < 2000);

      if (fromTheLeft) {
        const src = this.left[srcId - 1000];
        if (targetId === 'right') {
          this.right.push(src);
        } else if (toRight) {
          this.right.splice(targetId - 2000, 0, src);
        }
      } else {
        if (toTheLeft) {
          this.right.splice(srcId - 2000, 1);
        } else {
          // right to right
          const src = this.right[srcId - 2000];
          this.right.splice(srcId - 2000, 1);
          this.right.splice(targetId - 2000, 0, src);
        }
      }

      evt.target.classList.remove('hover');
      evt.target.parentNode.classList.remove('hover');
    },
    onDragEnter(evt) {
      const target = evt.target;
      const parent = target.parentNode;
      if (parent.id === 'left') {
        // console.log('hover on [0]:', parent.id);
        parent.classList.add('hover');
      } else if (target.id === 'left' || parent.id === 'right') {
        // console.log('hover on [1]:', target.id);
        target.classList.add('hover');
      } else if (target.id === 'right') {
        // console.log('hover on [2]:', target.id);
        target.classList.add('hover');
      }

      const targetOnTheRight = target.id === 'right' || parent.id === 'right';

      if (targetOnTheRight) {
        const left = document.getElementById('left');
        left.classList.remove('hover');
      }
    },
    onDragLeave(evt) {
      const target = evt.target;
      target.classList.remove('hover');
    }
  }
};
</script>

css
<style scoped>
.drop-zone {
  background-color: #eee;
  margin-bottom: 10px;
  padding: 10px;
}

.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
}
.hover {
  background-color: rgb(36, 123, 181);
}
</style>
